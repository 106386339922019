import {Link, useLocation, useResolvedPath} from '@remix-run/react';
import {RemixLinkProps} from '@remix-run/react/dist/components';
import {LucideIcon} from 'lucide-react';
import {forwardRef} from 'react';
import {cn} from '../utils';
import {Button, ButtonProps} from './button';

type ButtonLinkProps = ButtonProps & {
  to: string;
  icon?: LucideIcon;
  align?: 'text-left' | 'text-center' | 'text-right';
  exact?: boolean;
  colored?: boolean;
  target?: RemixLinkProps['target'];
};
const ButtonLink = forwardRef<HTMLButtonElement, ButtonLinkProps>(
  ({children, icon: Icon, variant, size, target, to, exact, colored, align = 'text-left', ...props}, ref) => {
    const {pathname} = useLocation();
    const resolvedPath = useResolvedPath(to);
    const isActive = exact ? pathname === resolvedPath.pathname : pathname.includes(resolvedPath.pathname);
    return (
      <Button
        ref={ref}
        variant={isActive || colored ? variant : 'translucent'}
        size={!children ? 'icon' : size}
        {...props}
        asChild>
        <Link target={target} to={to} className={cn(isActive ? 'bg-muted' : '', 'group')}>
          {!!Icon && (
            <Icon
              className={cn(
                children
                  ? 'w-4 h-4 group-hover:-translate-x-1 group-focus:-translate-x-1 transition-transform'
                  : 'w-6 h-6',
              )}
            />
          )}
          {!!children && <span className={cn(align, 'flex-1')}>{children}</span>}
        </Link>
      </Button>
    );
  },
);
ButtonLink.displayName = 'ButtonLink';

export {ButtonLink};
